import React, { useState, useEffect } from "react";
import { NavbarComp } from './NavbarComp';
import Form from "./Form";
import Homepage from "./Homepage";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import 'bootstrap/dist/css/bootstrap.min.css';

// you only need to call it once (on the App file) if using only one stylesheet
import "./App.scss";

const App = () => {
  const [textArray, setTextArray] = useState([]);
  // i add the ignore so it doesn't run again
  const [ignore, setIgnore] = useState(false);

  const getContent = async () => {
    const response = await fetch(
      "https://aminoreamore.com/wp/wp-json/api/random"
    );
    // nested html when rendering {event.content.rendered} - cleaning method use 'dangerouslysetinnerhtml'?
    const data = await response.json();

    setTextArray(data);
    return data;
  };

  // Similar to componentDidMount and componentDidUpdate:
  useEffect(() => {
    if (!ignore) {
      getContent();
      setIgnore(true);
    }
  }, [ignore, setIgnore]);

  return (
    <Router>
      <div className="App">
        <Switch>
          <Route
            path="/"
            exact
            render={props => (
              <Homepage
                {...props}
                textArray={textArray}
                setTextArray={setTextArray}
              />
              
            )}
           
          />
          <Route
            path="/form"
            exact
            render={props => <Form {...props} setTextArray={setTextArray} />}
          />
        </Switch>
      </div>
    </Router>
  );
};

export default App;
