import React, { useRef, useEffect } from "react";
import p5 from "p5";
//import biblio from "./fonts/biblio.otf";

const ForGerhard = ({
  text = "Gerhard, this sample is for animating stories we want to tell!",
}) => {
  const ref = useRef(null);
  // adding the extrsa space in the beggingin
  const textToRender = " " + text;

  //
  const Sketch = (p) => {
    var sizes;
    let pts;
    let caslon;
    var inc = 0.001;
    var alphaoff = 0;
    var alphaoff2 = 100;
    var alphaoff3 = 0.01;
    var start = 0;
    var n = 0;
    // only works with opentype fonts
    var g;
    var puffSize;
    let font;
    let fontData;
    // text varialbe comes from props (also)
    let words = textToRender.split(" ");
    let puff;
    p.preload = () => {
      font = p.loadFont("./biblio.otf");
    };
    var fade;
    var fadeAmount = 1;
    var Bungee;

    p.setup = () => {
      p.canvas = p.createCanvas(800, 500);
      // button = createButton("jump words");
      p.canvas.mousePressed(Change);
      fade = 255;
      //let words = [];

      p.textFont("Bungee");
      puff = font.textToPoints(words[n], p.width / 2, p.height / 2, 100, {
        sampleFactor: p.random(0.5, 0.1),
        simplfytreshold: 0,
      });
    };

    p.draw = () => {
      //0, 110, 191
      //14, 16, 28
      p.background(0, 110, 191);

      p.fill(255, 255, 255, 30);
      p.text(words[n - 1], 150, 80, 100);
      p.fill(255, 255, 255, fade);
      p.text(words[n], 150, 100, 105);
      p.fill(255, 255, 255, 30);
      p.text(words[n + 1], 150, 120, 100);

      var alpha = p.map(p.noise(alphaoff), 0, 1, 0, p.width);
      var alpha2 = p.map(p.noise(alphaoff2), 0, 1, 0, p.width);
      alphaoff += 0.01;
      alphaoff2 += 0.01;
      for (var piff = 0; puff < p.width; puff++) {
        sizes = p.map(p.noise(alphaoff), 0, 1, 0, p.width);
      }

      for (let i = 0; i < puff.length; i++) {
        p.fill(235, 255, 255, p.map(p.noise(alphaoff2), 0, 1, 0, p.width) / 10);
        //CHANGE RANDOM
        //p.ellipse(puff[i].x, puff[i].y, (alpha += 0.1) / 10, alpha / 10);
        p.ellipse(
          puff[i].x,
          puff[i].y,
          p.map(p.noise(alphaoff2), 0, 1, 0, p.width) / 10,
          alpha / 10
        );
        p.noStroke();
      }
      inc += 3;
      if (n > 0) {
        g = 0;
      } else {
        g = 255;
      }
      p.fill(255, 255, 255, g);
      p.text("click the screen", 350, 350);
      p.textSize(20);

      fade += fadeAmount;
      // console.log(alpha);
    };

    function Change() {
      fade = 0;
      fadeAmount = +3;
      n = n + 1;

      if (fade < 51) {
      }
      if (n === words.length) {
        n = 0;
      }
      puff = font.textToPoints(words[n], 100, 300, 180);
    }
  };

  // Similar to componentDidMount and componentDidUpdate:
  useEffect(() => {
    // only render p5 if text exists
    if (text) {
      ref.current.innerHTML = "";
      const myP5 = new p5(Sketch, ref.current);
    }
  });

  return (
    //This div will contain our p5 sketch
    <div ref={ref}></div>
  );
};

export default ForGerhard;
/* 
   let font;
    let pts;
  p.preload = () => {
      font = p.loadFont("./biblio.otf");
    }


p.background(0, 110, 191);
p.ellipse(100 , 100, 10);


p.translate(20, 140);
p.fill(255,0,0);
p.noStroke();
for(let i =0; i< pts.length; i++){
  p.fill(0);
  p.ellipse(pts[i].x, pts[i].y, 2,2); 
};






*/
