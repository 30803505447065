import React from "react";
import ForGerhard from "./ForGerhard";
import './App.css'
import 'animate.css';
import { NavbarComp } from './NavbarComp';
import {Button, Nav, Navbar, Container, CardGroup,} from 'react-bootstrap'
/* import {SlideInDown} from "animate-css-styled-components";
<SlideInDown>
</SlideInDown> */

export default function Homepage({ textArray = [] }) {
  return (

    <div className="HomeCont">
    
      <div className="Homepage  animate__animated animate__fadeInDown animate__slow">
        
        {textArray.map(({ id = "", text = "" }, i) => (
          <div className="Homepage__item" key={`item--${id}-${i}`}>
            <ForGerhard text={text} />
          </div>
        ))}
        
       
      </div>
     
    </div>
    
  );
}
