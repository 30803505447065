import React, { useState, useEffect } from "react";
import { useForm } from "react-hook-form";
import { useHistory } from "react-router-dom";

export default function Form({ textArray = [], setTextArray = undefined }) {
  const history = useHistory();

  //
  const [categories, setCategories] = useState([]);
  const [loading, setLoading] = useState(false);
  const [msg, setMsg] = useState(false);
  const [check, setCheck] = useState(false);

  // i add the ignore so it doesn't run again
  const [ignore, setIgnore] = useState(false);

  // hook form stuff
  const {
    register,
    handleSubmit,
    formState: { errors }
  } = useForm();

  // get Categories
  const getCategories = async () => {
    const response = await fetch(
      "https://aminoreamore.com/wp/wp-json/api/categories"
    );
    // nested html when rendering {event.content.rendered} - cleaning method use 'dangerouslysetinnerhtml'?
    const data = await response.json();

    setCategories(data);
    return data;
  };

  // Similar to componentDidMount and componentDidUpdate:
  useEffect(() => {
    if (!ignore) {
      getCategories();
      setIgnore(true);
    }
  }, [ignore, setIgnore]);

  const onSubmit = async data => {
    setLoading(true);
    setMsg(false);

    var formdata = new FormData();
    formdata.append("name", data["name"]);
    formdata.append("event", data["event"]);
    formdata.append("email", data["email"]);
    formdata.append("type", data["type"]);
    formdata.append("message", data["message"]);
    formdata.append("updates", check);

    const res = await fetch("https://aminoreamore.com/wp/wp-json/api/post", {
      method: "POST",
      body: formdata,
      redirect: "follow"
    });
    const json = await res.json();

    if (json.success) {
      setLoading(false);
      setMsg(json.msg);
      // add to array and go to home
      const newItem = {
        id: "0001",
        text: data["message"]
      };
      setTextArray([newItem, ...textArray]);
      setTimeout(() => {
        history.push("/");
      }, 1000);
    } else {
      setLoading(false);
      setMsg(json.msg);
    }
  };

  const categoriesSelect = categories.map(({ id = "", name = "" }, i) => (
    <option value={id} key={"cat--" + i}>
      {name}
    </option>
  ));

  return (
    <div className="FormCont">
      <form
        className={`Form ${loading ? "is-loading" : ""}`}
        onSubmit={handleSubmit(onSubmit)}
      >
        <h1>Queer story entries</h1>
        <div className="Form__input">
          <input
            type="text"
            placeholder="First and Last name:"
            {...register("name", {
              required: true,
              maxLength: 20
            })}
          />
        </div>
        <div className="Form__input">
          <input
            type="text"
            placeholder="When did your event occur?"
            {...register("event", {
              required: true,
              maxLength: 20
            })}
          />
        </div>
        <div className="Form__input">
          <input
            type="text"
            placeholder="Email"
            {...register("email", { required: true, pattern: /^\S+@\S+$/i })}
          />
        </div>
        <h3>(No personal details will be published!)</h3>
        <div className="Form__input">
          <select {...register("type", { required: true })}>
            {categoriesSelect}
          </select>
        </div>
        <br />
        <br />
        <div className="Form__input">
          <textarea
            {...register("message", { required: true, maxLength: 99 })}
          />
        </div>
        <h2>
          <label
            htmlFor="updates"
            className={check ? "checkbox is-active" : "checkbox"}
            onClick={e => setCheck(!check)}
          >
            I wish to recieve further updates how my story lives on by email
            <span />
          </label>
        </h2>

        <input className="btn" type="submit" value="Submit" />
      </form>
    </div>
  );
}
